<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">添加API</h5>
        </a-col>
        <a-col :span="24" :md="12">
          <!--          <a-row type="flex" justify="end">-->
          <!--            <a-col :span="6" style="display: flex; align-items: center; justify-content: flex-end">-->
          <!--              <a-radio-group v-model="authorsHeaderBtns" size="small">-->
          <!--                <a-radio-button value="all">全部</a-radio-button>-->
          <!--                <a-radio-button value="online">进行中</a-radio-button>-->
          <!--                <a-radio-button value="online">已结束</a-radio-button>-->
          <!--              </a-radio-group>-->
          <!--            </a-col>-->
          <!--            <a-col :span="6" style="display: flex; align-items: center; justify-content: flex-end">-->
          <!--              <a-button type="primary" icon="plus" size="small">-->
          <!--                创建新任务-->
          <!--              </a-button>-->
          <!--            </a-col>-->
          <!--          </a-row>-->
        </a-col>
      </a-row>
    </template>
    <a-row type="flex" align="middle" style="justify-content: center;">
      <a-space direction="vertical" :size="16" style="width: 71%;">
        <a-alert
            message="风险提示："
            description="一个交易账户建议只接入一个交易API，否则多个交易员同时操作一个账户会导致资金风险，风险自负！如需分仓交易，请准备多个账户（或平台子账户），分别添加对应API。"
            type="error"
            show-icon
            closable
        />
      </a-space>
    </a-row>
    <a-form
        :form="form"
        class="mt-30"
        :hideRequiredMark="true"
        style="padding: 0 24px;"
    >
      <a-row type="flex" :gutter="[24]">
        <a-col :span="24" :md="20" :lg="18" class="mx-auto">
          <a-row class="checkbox-group" type="flex" :gutter="[50]">
            <a-col :span="8">
              <div class="checkbox-label"
                   :class="[selectedOption === '欧意OKX' ? 'active' : '']"
                   @click="toggleCheckbox('欧意OKX')"
              >
                <h6>欧意OKX</h6>
              </div>

            </a-col>
            <a-col :span="8">
              <div class="checkbox-label"
                   :class="[selectedOption === '币安Binance' ? 'active' : '']"
                   @click="toggleCheckbox('币安Binance')"
              >
                <h6>币安Binance</h6>
              </div>

            </a-col>
            <a-col :span="8">
              <div class="checkbox-label"
                   :class="[selectedOption ==='火币Huobi' ? 'active' : '']"
                   @click="toggleCheckbox('火币Huobi')"
              >
                <h6 class="text-gray-6">火币Huobi（待接入）</h6>
              </div>

            </a-col>
          </a-row>
          <a-radio-group class="d-none" v-model="selectedOption" :options="checkboxOptions"/>
        </a-col>
      </a-row>
      <a-row :gutter="[24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == '欧意OKX'">
        <a-col :span="24" :md="14">
          <a-form-item class="mb-10" label="API备注名" :colon="false">
            <a-input placeholder="eg. 跟单猿OKX实盘" v-model="api_name"/>
          </a-form-item>
          <a-form-item class="mb-10" label="API类型" :colon="false">
            <a-radio-group v-model:value="flag" button-style="solid">
              <a-radio-button value="0">实盘</a-radio-button>
              <a-radio-button value="1">模拟盘</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item class="mb-10" label="申请V5 API时设置的密码短语(PassPhrase)" :colon="false">
            <a-input placeholder="PassPhrase" v-model="passPhrase" v-decorator="['passPhrase',{ rules: [{ required: true, message: '请输入你的PassPhrase！' }, { min: 8, max: 32, message: '长度为 8-32 个字符' }] },]"/>
          </a-form-item>
          <a-form-item class="mb-10" :colon="false">
            <template v-slot:label>
              <span>填写 API 信息</span>
              <a-popover placement="topLeft">
                <template #content>
                  <p style="line-height:100%;font-size: 12px;color: #595959">为了实现实时跟单，平台将接入您的交易API，</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">并将定时检测您的API权限是否为正常。请不</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">要在添接入API后添加资金权限，若导致您的</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">资金出现问题，请后果自负。</p>
                </template>
                <a-icon type="info-circle" theme="twoTone" style="vertical-align: middle; margin-left: 5px"/>
              </a-popover>
            </template>
            <a-input placeholder="在此输入Api Key" v-model="api_key" v-decorator="['api_key',{ rules: [{ required: true, message: '请输入你的Api Key！' }, { min: 24, message: 'Api Key长度不能少于24位！' }] },]"/>
          </a-form-item>
          <a-form-item class="mb-10" :colon="false">
            <a-input placeholder="在此输入Secret Key" v-model="secret_key" v-decorator="['secret_key',{ rules: [{ required: true, message: '请输入你的Secret Key！' }, { min: 24, message: 'Api Key长度不能少于24位！' }] },]"/>
          </a-form-item>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            <a-icon type="warning" theme="outlined" />
            请注意，API信息仅用于交易，请勿泄露
          </span>
          <br/>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;仅支持包含交易权限的API接入
          </span>
          <br/>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;您的API将被多次加密，请放心提交
          </span>
          <br/>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: start; margin-top: 33px; margin-bottom: 80px;padding: 0">
            <a-button :loading="isLoading" type="primary" style="flex-grow: 1;" @click="submit">
              授 权 接 入
            </a-button>
          </a-col>
        </a-col>
      </a-row>
      <a-row :gutter="[24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == '币安Binance'">
        <a-col :span="24" :md="14">
          <a-form-item class="mb-10" label="API备注名" :colon="false">
            <a-input placeholder="eg. 跟单猿币安实盘" v-model="api_name"/>
          </a-form-item>
          <a-form-item class="mb-10" label="API类型" :colon="false">
            <a-radio-group v-model:value="flag" button-style="solid">
              <a-radio-button value="0">实盘</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item class="mb-10" :colon="false">
            <template v-slot:label>
              <span>填写 API 信息</span>
              <a-popover placement="topLeft">
                <template #content>
                  <p style="line-height:100%;font-size: 12px;color: #595959">为了实现实时跟单，平台将接入您的交易API，</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">并将定时检测您的API权限是否为正常。请不</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">要在添接入API后添加资金权限，若导致您的</p>
                  <p style="line-height:100%;font-size: 12px;color: #595959">资金出现问题，请后果自负。</p>
                </template>
                <a-icon type="info-circle" theme="twoTone" style="vertical-align: middle; margin-left: 5px"/>
              </a-popover>
            </template>
            <a-input placeholder="在此输入Api Key" v-model="api_key" v-decorator="['api_key',{ rules: [{ required: true, message: '请输入你的Api Key！' }, { min: 24, message: 'Api Key长度不能少于24位！' }] },]"/>
          </a-form-item>
          <a-form-item class="mb-10" :colon="false">
            <a-input placeholder="在此输入Secret Key" v-model="secret_key" v-decorator="['secret_key',{ rules: [{ required: true, message: '请输入你的Secret Key！' }, { min: 24, message: 'Api Key长度不能少于24位！' }] },]"/>
          </a-form-item>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            <a-icon type="warning" theme="outlined" />
            请注意，API信息仅用于交易，请勿泄露
          </span>
          <br/>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;仅支持包含交易权限的API接入
          </span>
          <br/>
          <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;您的API将被多次加密，请放心提交
          </span>
          <br/>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: start; margin-top: 33px; margin-bottom: 80px;padding: 0">
            <a-button :loading="isLoading" type="primary" style="flex-grow: 1;" @click="submit">
              授 权 接 入
            </a-button>
          </a-col>
        </a-col>
      </a-row>
    </a-form>

  </a-card>

</template>

<script>
import http from "../utils/http"
import { message } from 'ant-design-vue';

const key = 'updatable';

export default {
  name: "Apiadd",
  components: {},
  data() {
    return {
      // 选择平台
      selectedOption: '欧意OKX',

      // Checkbox'es array of all options.
      checkboxOptions: ['欧意OKX', '币安Binance', '火币Huobi'],

      // Step's form object
      form: this.$form.createForm(this, {name: 'apiadd'}),

      // platform_choice: toplatform(selectedOption),//平台
      flag: '0',//API类型
      api_name: '',//API备注名
      passPhrase: '',//API密钥
      api_key: '',//API密钥
      secret_key: '',//API密钥

      // 提交状态
      isLoading: false,
    }
  },
  methods: {
    // Toggle an item from the checkbox.
    toggleCheckbox(item) {
      if (item === '火币Huobi') {
        this.selectedOption = '欧意OKX';
        return;
      } else {
        this.selectedOption = item;
        return;
      }
    },
    toplatform(selectedOption) {
      if (selectedOption === '欧意OKX') {
        return '1';
      } else if (selectedOption === '币安Binance') {
        return '2';
      } else if (selectedOption === '火币Huobi') {
        return '3';
      }
    },
    async submit() {
      // 验证表单字段是否为空
      if (!this.selectedOption || !this.flag || !this.api_key || !this.secret_key || !this.api_name) {
        this.$message.error('所有字段都是必填的，请确保填写完整。', 2);
        return; // 终止执行
      }

      this.isLoading = true; // 开始加载，禁用按钮
      this.$message.loading({ content: 'API校验中，请稍后...', key, duration: 0 });
      setTimeout(async () => {
        try {
          const platform = this.toplatform(this.selectedOption)
          const flag = this.flag
          const passPhrase = this.passPhrase
          const api_key = this.api_key
          const secret_key = this.secret_key
          const api_name = this.api_name
          const response = await http.post('/api/apiadd/?token=' + localStorage.getItem("token"), {
            platform,
            api_name,
            flag,
            passPhrase,
            api_key,
            secret_key,
          });
          if (response.data.code === 0) {
            this.$message.success({ content: "添加成功！", key, duration: 2 });
            // 服务器响应成功后，清空表单
            this.resetForm();
            console.log(response.data);
            this.$router.push({path: '/apilist'})
          }else if (response.data.code === 1001){
            this.$message.error({ content: '请填写完整API信息', key, duration: 2 });
          }else{
            this.$message.error({ content: response.data.error, key, duration: 2 });
          }
        }catch (error) {
          console.error("提交时发生错误:", error);
          this.$message.error({ content: '网络请求失败，请联系管理员', key, duration: 2 });
          // 处理错误
        } finally {
          this.isLoading = false; // 无论成功或失败，加载结束后启用按钮
        };
      }, 1000);

    },
    resetForm() {
      this.api_name = '';
      this.flag = '0'; // 根据您的逻辑设置默认值
      this.passPhrase = '';
      this.api_key = '';
      this.secret_key = '';
      this.selectedOption = '欧意OKX';
    },
  },

  mounted() {
    this.info();
  },
}
</script>

<style scoped>
.mt-30 {
  font-weight: 600;
}



</style>